@layer components {

  .headline {
    @apply text-gray-600 font-normal leading-tight uppercase mb-12 relative pb-6 pt-5 px-4;
    font-size: 2.125rem; /* 34px ._. */

    &:before {
      /* dot grid pattern */
      @apply block w-32 h-48 absolute;
      content: '';
      opacity: 0.05;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAH0lEQVQYlWNgoCYwZmBgSIPSWEEaAwPDfyhNngk0AgD5tgNke3is6QAAAABJRU5ErkJggg==');
      background-size: 13px;
      top: -4.8rem;
    }

    &.headline--sm {
      @apply text-3xl mb-16;

      &:before {
        top: -5rem;
      }
    }

    &.headline--fade {
      &:before {
         background-image:
            linear-gradient(to top, transparent 50%, white),
            url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAH0lEQVQYlWNgoCYwZmBgSIPSWEEaAwPDfyhNngk0AgD5tgNke3is6QAAAABJRU5ErkJggg==');
      }
    }

    &.headline--center {
      @apply text-center;

      &:before,
      &:after {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }

  @screen sm {
    .headline {
      @apply mb-20 px-16;
    }
  }

}
