.faq {
  @apply mt-8 pt-4 relative;

  &:before {
    @apply block absolute bg-gray-200;
    top: 0;
    right: 60%;
    bottom: -32px;
    width: 50vw;
    transform: translate(, 0);
    content: '';
  }

  & .faq-inner {
    position: relative;
  }
}

.js-badger-accordion-panel-inner,
.badger-accordion__header {
  @apply bg-white;
}

.badger-accordion__header {
  @apply mt-1;
  transition: all .2s ease-in-out;

  & div {
    @apply border-t border-l border-r border-white;
    transition: all 0.2s ease-in-out;
  }

  & div.-ba-is-active {
     @apply border-t border-l border-r border-blue-500 shadow-lg;
  }
}

.badger-accordion__panel {
  @apply border-b border-l border-r border-white;
  transition: all 0.2s ease-in-out !important;

  &.-ba-is-active {
    @apply border-b border-l border-r border-blue-500 shadow-lg;
  }
}

.badger-accordion__panel.-ba-is-active + .badger-accordion__header {
  @apply mt-4;
}

.badger-toggle-indicator {
  @apply w-5 h-8 bg-no-repeat bg-contain bg-center pr-4 pt-1;

  &:before,
  &:after {
    @apply block absolute w-5 rounded bg-gray-600;
    height: 4px;
    content: '';
  }

  &:after {
    @apply absolute;
    transform: rotate(90deg);
  }
}

.-ba-is-active .badger-toggle-indicator {
  &:after {
    transform: rotate(0);
  }
}

/* IE11 fix */
.badger-accordion__header .js-badger-accordion-header .badger-toggle-indicator {
  flex: 0 !important;
}
