.mobile-nav {

}

html.menu .mobile-nav {
  display: flex;
  animation: sl .3s ease-out;
}

@keyframes sl {
  0% {
    opacity: 0;
    transform: translate(0, -15px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
