
.multiselect,
.multiselect__tags {
  min-height: 54px;
  font-size: 1em;
}

.multiselect__tags {
  padding: 13px 54px 0 13px;
  /* border: none; */
  border-radius: 0;
}

.multiselect__content-wrapper {
  border-radius: 0;
}

.multiselect--disabled .multiselect__select {
  background: transparent;
}

.multiselect__single,
.multiselect__input {
  min-height: 27px;
  line-height: 27px;
}

.multiselect__placeholder {
  padding-left: 5px;
}

.multiselect__select {
  height: 50px;
}

.multiselect__option {
  min-height: 54px;
  line-height: 28px;
  padding-left: 17px;
}
.spinner {
  @apply text-black;
  display: inline-block;
  width: 24px;
  height: 24px;
  animation: spin 0.5s infinite linear;
  border: 4px solid;
  border-right-color: transparent;
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

