@import 'fonts';
@import 'tailwindcss/base';
@import 'tailwindcss/components';

@import 'components/headline';
@import 'components/footer';
@import 'components/button';
@import 'components/rte';
@import 'components/faq';
@import 'components/usp';
@import 'components/mobile-nav';
@import 'components/call-ribbon';
@import 'components/spinner';
@import 'components/scroll-tip';
@import 'components/multiselect';
@import 'components/show-more-button';

@import 'tailwindcss/utilities';

@import 'components/form';
@import 'components/inquiry-form';

@import 'utilities/effects';
@import 'utilities/backgrounds';
@import 'utilities/transitions';
@import 'utilities/misc';

input {
  border-radius: 0;
}

textarea, select, input, button { outline: none; }
a:focus,
button:focus {
  outline: none;
}
