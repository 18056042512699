@layer components {

  .button {
    @apply inline-block px-6 py-2 pb-3;
    @apply text-base text-gray-600 font-bold uppercase bg-hertz-yellow cursor-pointer border border-hertz-yellow;
    padding-bottom: .7rem;
    letter-spacing: 0.5px;
    transition: opacity 0.2s ease;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .button--anemic {
    @apply bg-white border border-black;
  }

  .button--full {
    @apply w-full;
  }

  .button--no-trans {
    transition: none;
  }

}
