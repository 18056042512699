.inquiry-form {
  @apply
    text-gray-600
    border-l-6 border-hertz-yellow;

  & p,
  & input[type="radio"] + label,
  & input[type="checkbox"] + label {
    @apply font-light;
  }

  & fieldset legend {
    @apply text-xl font-bold;
  }

  & input[type="text"]:not(.multiselect__input),
  & textarea {
    @apply
      bg-white border border-gray-500
      py-3 pb-4 px-5;

    &::placeholder {
      @apply text-gray-500;
    }

    &:focus {
      @apply outline-none text-black;
    }

    &.valid:focus {
      @apply border-black;
    }

    &.invalid {
      @apply border-red-500;
    }
  }

  & .radio,
  & .checkbox {
    & label:before {
      @apply w-6 h-6 bg-white mr-3 rounded-full border-2 border-blue-500;
    }

    & input:checked + label:before {
      background: radial-gradient(theme('colors.blue.500') 0%, theme('colors.blue.500') 50%, white 58%, white 100%), white;
    }

    &.checkbox input:checked + label:before {
      background-image: url(~@/assets/svg/icons/checkmark-blue.svg);
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: 50% 50%;
    }
  }

  & .flatpickr-input {
    @apply block w-full bg-white text-gray-600 border border-gray-500 py-3 pb-4 px-5;

    @nest .error & {
      @apply border-red-500;
    }

    &::placeholder {
      @apply text-gray-500;
    }
  }

  & .flatpickr-calendar {
    background-color: #dfdfdf;
    border-top: 6px solid theme('colors.hertz-yellow');

    &.arrowTop::after {
      border-bottom-color: theme('colors.hertz-yellow');
      border-width: 20px;
    }

    &.arrowBottom::after {
      border-top-color: #dfdfdf;
      border-width: 20px;
    }
  }
}

.inquiry-form__inner {
  @apply bg-gray-200 border-r border-b border-t border-gray-300 px-4 py-6;
}

@screen sm {
  .inquiry-form__inner {
    @apply px-8 py-8;
  }
}

.inquiry-form__submit {
  @apply button flex justify-center items-center;
}

.inquiry-form__error {
  @apply text-red-500 text-xs absolute mt-px;
}

.inquiry-form__info-panel {
  @apply px-4 py-3 text-sm rounded bg-gray-300;
}

.inquiry-form {
  & .multiselect {
    &.invalid {
      @apply border-red-500;
    }
  }

  & .multiselect__content-wrapper {
    border: none;
  }

  & .multiselect__option--selected.multiselect__option--highlight,
  & .multiselect__option--highlight {
    background: rgb(43, 124, 206);
  }

  & .multiselect__option--selected {
    background: rgb(226, 232, 240);
    font-weight: 500;
  }
}
