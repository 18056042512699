/* Webfont: Ride */@font-face {
    font-family: 'Ride';
    src: url('~@/assets/fonts/ride/Ride.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/ride/Ride.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/ride/Ride.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/ride/Ride.ttf') format('truetype'), /* Safari, Android, iOS */
         url('~@/assets/fonts/ride/Ride.svg#Ride') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    text-rendering: optimizeLegibility;
}

/* Webfont: Ride-Italic */@font-face {
    font-family: 'Ride';
    src: url('~@/assets/fonts/ride/Ride-Italic.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/ride/Ride-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/ride/Ride-Italic.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/ride/Ride-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('~@/assets/fonts/ride/Ride-Italic.svg#Ride-Italic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    font-display: swap;
    text-rendering: optimizeLegibility;
}

/* Webfont: Ride-Bold */@font-face {
    font-family: 'Ride';
    src: url('~@/assets/fonts/ride/Ride-Bold.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/ride/Ride-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/ride/Ride-Bold.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/ride/Ride-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('~@/assets/fonts/ride/Ride-Bold.svg#Ride-Bold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    text-rendering: optimizeLegibility;
}

/* Webfont: Ride-BoldItalic */@font-face {
    font-family: 'Ride';
    src: url('~@/assets/fonts/ride/Ride-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/ride/Ride-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/ride/Ride-BoldItalic.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/ride/Ride-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('~@/assets/fonts/ride/Ride-BoldItalic.svg#Ride-BoldItalic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: bold;
    font-display: swap;
    text-rendering: optimizeLegibility;
}

/* Webfont: RideLight */@font-face {
    font-family: 'Ride';
    src: url('~@/assets/fonts/ride/RideLight.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/ride/RideLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/ride/RideLight.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/ride/RideLight.ttf') format('truetype'), /* Safari, Android, iOS */
         url('~@/assets/fonts/ride/RideLight.svg#RideLight') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: light;
    font-display: swap;
    text-rendering: optimizeLegibility;
}

/* Webfont: RideLight-Italic */@font-face {
    font-family: 'Ride';
    src: url('~@/assets/fonts/ride/RideLight-Italic.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/ride/RideLight-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/ride/RideLight-Italic.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/ride/RideLight-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('~@/assets/fonts/ride/RideLight-Italic.svg#RideLight-Italic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: light;
    font-display: swap;
    text-rendering: optimizeLegibility;
}
