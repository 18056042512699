.slide-enter-active {
  transition: all .4s ease-out;
}

.slide-leave-active {
  /*transition: all .8s cubic-bezier(0.600, -0.280, 0.735, 0.045);*/
  transition: all .8s ease-in;
}

.slide-enter,
.slide-leave-to {
  transform: translate(100%, 0);
}

.vertical-full-enter-active,
.vertical-full-leave-active {
  transition: all .3s ease-in;
}

.vertical-full-enter,
.vertical-full-leave-to {
  transform: translate3d(0, 100%, 0);
}

.vertical-xs-enter-active,
.vertical-xs-leave-active {
  transition: all .2s ease;
}

.vertical-xs-enter,
.vertical-xs-leave-to {
  transform: translate(0, -5px);
  opacity: 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.quickfade-enter,
.quickfade-leave-to {
  opacity: 0;
}

.quickfade-enter-active,
.quickfade-leave-active {
  transition: opacity 0.05s;
}
