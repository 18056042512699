@layer components {
  .footer {
    @apply border-b-6 border-hertz-yellow relative;
  }

  .footer:after {
    @apply bg-hertz-yellow absolute block w-full;
    content: '';
    height: 2px;
    bottom: 8px;
  }
}
