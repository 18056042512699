.fade-white-b:after,
.fade-white-t:before {
  @apply block absolute w-full h-16;
  content: '';
}

.fade-white-b:after {
  @apply bottom-0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 40%, rgba(255, 255, 255, 1) 100%);
}

.fade-white-t:before {
  @apply top-0;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 40%, rgba(255, 255, 255, 1) 100%);
}

.strikethru {
  @apply relative inline-block px-2 no-underline;

  &:after {
    @apply block absolute h-px bg-black opacity-50;
    background-color: currentColor;
    top: 55%;
    left: -3px;
    right: -3px;
    content: '';
  }
}

.hertz-underline {
  @apply relative pb-4;

  &:after {
    @apply absolute block w-16 h-2 mt-3 bg-hertz-yellow;
    left: 3px;
    content: '';
  }
}

@responsive {
  .hertz-underline--left {
    left: -3px;
  }

  .hertz-underline--right::after {
    left: auto;
    right: 0;
  }

  .hertz-underline--center::after {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
