@layer components {
  .show-more-btn {
     @apply w-10 h-10 rounded-full border-2 border-gray-600 p-1;

     & > svg {
      @apply w-6 h-6 mt-1 mx-auto relative;
      transform: rotate(90deg);

      & path {
        fill: theme('colors.gray.600') !important;
      }
    }
  }

  .show-more-btn--filled {
    @apply bg-hertz-yellow border-hertz-yellow;
  }
}
