/* @import 'vue-multiselect/dist/vue-multiselect.min.css'; */

@layer components {
  .filter {
    & .multiselect {
      @apply text-sm;
      color: currentColor;
      min-height: 35px;
      /*overflow: visible;*/
    }

    & .multiselect__tags {
      @apply p-0 pl-2;
      border-radius: 0;
      min-height: 35px;
      border-color: theme('colors.gray.400');
      font-size: 13px;
    }

    & .multiselect__tag {
      background: theme('colors.hertz-yellow');
      padding-top: 1px;
      position: relative;
      top: 6px;
      margin-right: 5px;
    }

    & .multiselect__tag-icon {
      line-height: 14px;
      width: 14px;
      margin-left: 4px;

      &:after {
        color: white;
        font-size: 1em;
      }

      &:focus,
      &:hover {
        background: inherit;
      }
    }

    & .multiselect__placeholder,
    & .multiselect__single {
      @apply m-0 p-0 pl-2 text-sm;
      padding-top: 4px;
      color: currentColor;
    }

    & .multiselect--active .multiselect__placeholder {
      display: block;
    }

    & .multiselect__spinner:before,
    & .multiselect__spinner:after {
      border-color: theme('colors.hertz-yellow') transparent transparent;
    }

    & .multiselect__select {
      @apply m-0 p-0 right-0 top-0 z-10;
      transition: transform 0.1s ease;
      height: 35px;
      width: 35px;

      &:before {
        @apply m-0 p-0;
        top: 67%;
        border-top-color: black;
        color: black;
      }
    }

    & .multiselect__option {
      @apply p-0 pl-4;
      min-height: 35px;

      & span {
        line-height: 35px;
      }
    }

    & .multiselect__option:after {
      font-size: 1em;
      line-height: 35px;
    }

    & .multiselect__option--highlight,
    & .multiselect__option--highlight::after {
      background-color: theme('colors.hertz-yellow');
    }
  }

}
