[v-cloak] {
  visibility: hidden;
}

.cloak {
  display: none;
}

.lazyload,
.lazyloading {
  opacity: 0;
  transition: opacity 1s;
}

.lazyloaded {
  opacity: 1;
}
